import { Component, OnInit } from '@angular/core';
import {first} from "rxjs/operators";
import {Props} from "../_models/props";
import {PropertyService} from "../_services/property.service";
import {ActivatedRoute} from "@angular/router";
import {ErrorService} from "../_services/error.service";
import {Error} from "../_models/error";

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  baseHref:string;
  code:string;
  error:Error = {title: "Ошибка авторизации", message : "Обратитесь к своему системному администратору"};

  constructor(private route: ActivatedRoute,
              private propertyService: PropertyService,
              private errorService: ErrorService) {}

  ngOnInit() {
    this.code = this.route.snapshot.queryParams['code'] || 'standard_code';

    this.errorService.getError(this.code)
      .pipe(first())
      .subscribe(
        (error: Error) => {
          console.log(error);
          this.error = error;
        },
        httpErrorResponse => {
          console.log(httpErrorResponse);
          this.error = {title: "Ошибка авторизации", message : "Обратитесь к своему системному администратору"};
        });

    this.propertyService.getProperty("front.base.href")
      .pipe(first())
      .subscribe(
        (url: Props) => {
          this.baseHref = url.value;
        },
        httpErrorResponse => {
          this.baseHref = '/';
        });
  }

}
