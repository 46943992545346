import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AuthenticationService } from "./_services/authentication.service";
import { PropertyService } from "./_services/property.service";
import { ErrorComponent } from './error/error.component';
import { SafeHtmlPipe } from "./_pipes/sife-html.pipe";
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { InternalAuthorizationComponent } from "./internal-authorization/internal-authorization.component";
import { InternalAuthorizationService } from "./_services/internal-authorization.service";

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    SafeHtmlPipe,
    InternalAuthorizationComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgZorroAntdModule,
    FormsModule,
    BrowserAnimationsModule,
  ],
  providers: [
    AuthenticationService,
    PropertyService,
    InternalAuthorizationService,
    { provide: NZ_I18N, useValue: en_US },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
